import moment from 'moment-timezone'

import {
  getEditImageFiles,
  getPathFormFile,
  getTypeFormFile,
} from '@a4b/ui/src/modules/admin/components/FileUpload'

import { Shrine } from '@a4b/api/src/modules/Temple/types'

import {
  FORM_CONFIG,
  FORM_ITEM_TYPE,
  GOD_ITEM_TYPES,
} from '../../god-items/dynamic-items-definitions'
import { ITEM_FORM_DEFAULT_FEILDS_SET } from '../components/schedule-forms/god-items-schedule-form'

// Parse Item Schedule Response
export const parseGodItemScheduleResponse = (defaultItem: any) => {
  console.log('data before parse', defaultItem)
  const itemCopy: any = { ...defaultItem }
  // @ts-ignore
  itemCopy['attachment'] = defaultItem?.attachment
    ? getEditImageFiles([defaultItem['attachment']])
    : undefined
  itemCopy['thumbnail'] = defaultItem?.thumbnail
    ? getEditImageFiles([defaultItem['thumbnail']])
    : undefined

  if (defaultItem?.property) {
    Object.keys(defaultItem.property).forEach(propertyKey => {
      // @ts-ignore
      const config = FORM_CONFIG[defaultItem.type]
      if (
        config[propertyKey]?.type === FORM_ITEM_TYPE.STRING ||
        config[propertyKey]?.type === FORM_ITEM_TYPE.NUMBER
      ) {
        // @ts-ignore
        itemCopy[propertyKey] = defaultItem.property[propertyKey]
      } else {
        //@ts-ignore
        if (propertyKey === 'states') {
          //@ts-ignore
          itemCopy['0'] = defaultItem.property[propertyKey]['0']
            ? getEditImageFiles([defaultItem.property[propertyKey]['0']])
            : undefined
          //@ts-ignore
          itemCopy['100'] = defaultItem.property[propertyKey]['100']
            ? getEditImageFiles([defaultItem.property[propertyKey]['100']])
            : undefined
          return
        }

        // @ts-ignore
        itemCopy[propertyKey] =
          defaultItem.property[propertyKey] &&
            defaultItem.property[propertyKey].type !== ''
            ? //@ts-ignore
            getEditImageFiles([defaultItem.property[propertyKey]])
            : undefined
      }
    })
  }

  //   if (defaultItem?.property) {
  //     Object.keys(defaultItem.property).forEach(propertyKey => {
  //       // @ts-ignore
  //       const config = FORM_CONFIG[defaultItem.type]
  //       if (
  //         config[propertyKey]?.type === FORM_ITEM_TYPE.STRING ||
  //         config[propertyKey]?.type === FORM_ITEM_TYPE.NUMBER
  //       ) {
  //         // @ts-ignore
  //         itemCopy[propertyKey] = defaultItem.property[propertyKey]
  //       } else {
  //         //@ts-ignore
  //         if (propertyKey === 'states') {
  //           //@ts-ignore
  //           itemCopy['0'] = defaultItem.property[propertyKey]['0']
  //             ? getEditImageFiles([defaultItem.property[propertyKey]['0']])
  //             : undefined
  //           //@ts-ignore
  //           itemCopy['100'] = defaultItem.property[propertyKey]['100']
  //             ? getEditImageFiles([defaultItem.property[propertyKey]['100']])
  //             : undefined
  //           return
  //         }

  //         // @ts-ignore
  //         itemCopy[propertyKey] =
  //           defaultItem.property[propertyKey] &&
  //           defaultItem.property[propertyKey].type !== ''
  //             ? //@ts-ignore
  //               getEditImageFiles([defaultItem.property[propertyKey]])
  //             : undefined
  //       }
  //     })
  //   }
  if (defaultItem?.access_type === 'coins_based') {
    itemCopy['coins_cost'] = defaultItem.coins_threshold
    itemCopy['validity_period'] = defaultItem.validity_prd_secs
  }
  if (defaultItem?.access_type === 'days_active_based') {
    itemCopy['coins_cost'] = defaultItem.coins_threshold
    itemCopy['validity_period'] = defaultItem.validity_prd_secs
    itemCopy['days_active'] = defaultItem.days_active_threshold
  }

  itemCopy['classification'] = defaultItem?.property?.['classification']
    ? defaultItem?.property?.['classification']
    : 'others'

  // handling audio attachment url for item type is prayer
  if (itemCopy.type === GOD_ITEM_TYPES.PRAYER) {
    itemCopy['audio_attachment'].path = itemCopy[
      'audio_attachment'
    ].url.replace(/^.*\/\/[^\/]+/, '')
    delete itemCopy['audio_attachment'].url
  }

  // handling date range for vishash
  if (itemCopy.type === GOD_ITEM_TYPES.VISHESH) {
    // @ts-ignore
    itemCopy['date_range'] = [
      moment(defaultItem['start_time']),
      moment(defaultItem['end_time']),
    ]
  }
  if (itemCopy.schedule_at) {
    itemCopy['schedule_at'] = moment(itemCopy.schedule_at)
  }
  itemCopy['cancel_schedule'] = defaultItem?.cancel_schedule ?? false
  itemCopy['active'] = defaultItem?.active ?? false

  console.log({ itemCopy })
  return itemCopy
}

// Prepare Item Schedule payload
export const prepareGodItemSchedulePayload = (
  data: any,
  itemId: string,
): Shrine.CreateItemSchedulePayload | null => {
  if (itemId === undefined) return null

  const { cancel_schedule, schedule_at, ...values } = data
  const itemUpdate: any = {
    type: values.type,
    active: values?.active ?? false,
    attachment: {
      path: getPathFormFile(values.attachment),
      type: getTypeFormFile(values.attachment),
    },
    property: {},
    access_type: values.access_type,
    available_for_all_gods: !!values.available_for_all_gods,
  }
  if (values.thumbnail) {
    itemUpdate['thumbnail'] = {
      path: getPathFormFile(values.thumbnail),
      type: getTypeFormFile(values.thumbnail),
    }
  }

  if (values.access_type === 'coins_based') {
    itemUpdate['coins_cost'] = values.coins_cost
    itemUpdate['validity_period'] = values.validity_period
  }
  if (values.access_type === 'days_active_based') {
    itemUpdate['coins_cost'] = values.coins_cost
    itemUpdate['days_active'] = values.days_active
    itemUpdate['validity_period'] = values.validity_period
  }

  const property: { [key: string]: string | { type: string; path: string } } =
    {}
  Object.keys(values).forEach((fieldName: string) => {
    if (!ITEM_FORM_DEFAULT_FEILDS_SET.has(fieldName)) {
      // @ts-ignore
      const godItem = FORM_CONFIG[values.type]
      console.log({ godItem }, { fieldName })
      if (godItem[fieldName].type === FORM_ITEM_TYPE.DATE_RANGE) {
        // @ts-ignore
        itemUpdate[godItem[fieldName].field_names[0]] = moment(
          values[fieldName][0],
        )
          .startOf('day')
          .valueOf()
        // @ts-ignore
        itemUpdate[godItem[fieldName].field_names[1]] = moment(
          values[fieldName][1],
        )
          .endOf('day')
          .valueOf()
        return
      }
      if (
        godItem[fieldName].type === FORM_ITEM_TYPE.STRING ||
        godItem[fieldName].type === FORM_ITEM_TYPE.NUMBER
      ) {
        property[fieldName] = values[fieldName]
      } else {
        property[fieldName] = {
          path: getPathFormFile(values[fieldName]),
          type: getTypeFormFile(values[fieldName]),
        }
      }
    }
  })
  if (values.type === GOD_ITEM_TYPES.IGNITABLE) {
    //@ts-ignore
    property['states'] = {}
    //@ts-ignore
    property['states']['0'] = property['0']
    //@ts-ignore
    property['states']['100'] = property['100']
    delete property['100']
    delete property['0']
  }
  itemUpdate['property'] = property

  // removing coin cost and validity period for prayer type
  if (itemUpdate.type === GOD_ITEM_TYPES.PRAYER) {
    delete itemUpdate['coins_cost']
    delete itemUpdate['validity_period']
  }

  const payload: Shrine.CreateItemSchedulePayload = {
    code: values.code as string,
    item_id: itemId,
    schedule_at: schedule_at.valueOf(),
    cancel_schedule: cancel_schedule as boolean,
    item_update: itemUpdate,
  }

  return payload
}

// Prepare God Schedule payload
export const prepareGodSchedulePayload = (
  values: any,
  godId: string,
): Shrine.GodSchedulePayload => {
  const valuesCopy = JSON.parse(JSON.stringify(values))
  const godUpdate = {
    active: values?.active,
    thumbnails: {
      large: {
        path: getPathFormFile(valuesCopy.largeThumbnail),
        type: getTypeFormFile(valuesCopy.largeThumbnail),
      },
      mini: {
        path: getPathFormFile(valuesCopy.miniThumbnail),
        type: getTypeFormFile(valuesCopy.miniThumbnail),
      },
      small_round: {
        path: getPathFormFile(valuesCopy.smallRoundThumbnail),
        type: getTypeFormFile(valuesCopy.smallRoundThumbnail),
      },
      small_square: {
        path: getPathFormFile(valuesCopy.smallSquareThumbnail),
        type: getTypeFormFile(valuesCopy.smallSquareThumbnail),
      },
    },
    festive_special: values.displayStatus === 'festive_special',
    position: values.position,
    pre_selected: values.displayStatus === 'pre_selected',
    loader_text: values.loader_text,
  }
  const payload = {
    god_id: godId,
    code: valuesCopy.code,
    language_code: values?.languages?.[0]?.language_code,
    schedule_at: values?.schedule_at
      ? values?.schedule_at?.valueOf()
      : undefined,
    cancel_schedule: values?.cancel_schedule,
    god_update: godUpdate,
  }
  return payload
}

export const parseAvatarScheduleData = (
  avatar: Shrine.AvatarPayload,
): Shrine.AvatarPayload => {
  const targetConfig = avatar?.promotional_avatar_details?.target_config as object
  const transpiledAvatar = {
    ...avatar,
    promotional_avatar_details: {
      ...avatar?.promotional_avatar_details,
      target_config: JSON.stringify({ ...targetConfig }, null, '\t'),
    },
  };
  return transpiledAvatar;
};
