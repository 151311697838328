export const sectionPageKeys = [
    'store_list_page_pooja',
    'srimandir_services_page',
    'banner_large_items_slp',
    'order_history_l2_page_offerings',
    'order_history_l2_page_pooja',
    'sankalp_banners_slp',
    'promotional_banners_v2_slp',
    'promotional_banners_slp',
    'store_list_page_offerings_mahakumbh_mahadaan',
    'store_list_page_offerings_mahakumbh_chadhava',
    'store_list_page_offerings_mahakumbh_mahadaan_app',
    'store_list_page_offerings_mahakumbh_chadhava_app',
  ]