import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from "react-router-dom"
import {
    Table,
    Space,
    Card,
    Button,
    Tag,
    Drawer,
    Col,
    Row,
    Typography,
    Tooltip,
    Input,
    Select,
    Badge,
    Spin,
    Modal,
    Checkbox,
    message
} from 'antd'
import styled from 'styled-components'
import { Product } from '@a4b/api/src/modules/Monetisation/Puja/types'
import { PlusSquareOutlined, SortAscendingOutlined, UploadOutlined } from '@ant-design/icons'
import OfferingItem from '@a4b/ui/src/modules/monetisation/puja/molecules/OfferingItem'
import { TablePaginationConfig } from 'antd/es/table'
import { CustomLoader } from '@/components/Loader'
import DragAndDropModal from '@/hoc/DragAndDropModal'
import usePujaStore from '@/modules/monetisation/puja/stores/puja-store'
import { A4B_APPS } from '@/utils/constants'
import { useAppContext } from '@/components/AppContext'

type AddedItems = { added: Product[] }

interface Props {
    AddedItems: AddedItems
    triggerEdit: (record: any) => void
    productList: Product[]
    createItem: () => void
    added: (item: Product) => void
    currentStep: number
    nextHandler: () => void
    previousHandler: () => void
    handleTableChange: (paginationParams: TablePaginationConfig) => void
    paginationParams: TablePaginationConfig
    loading: boolean
    sortBy?: ('price' | 'position' | 'created_at')
    updateSortBy: (sortBy?: ('price' | 'position' | 'created_at')) => void
    getStoreProductList: () => void
    resetTrigger: boolean
    setResetTrigger: (state: boolean) => void
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`
const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`
const ListAllItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: calc(100vh - 220px);
  overflow: scroll;
  background-color: #f5f5f5;
`

const LabelRightAlign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 10px;
`

const { Title } = Typography

const InventoryList: React.FC<Props> = ({
    AddedItems,
    triggerEdit,
    productList,
    createItem,
    added,
    currentStep,
    nextHandler,
    previousHandler,
    paginationParams,
    handleTableChange,
    loading,
    sortBy,
    updateSortBy,
    getStoreProductList,
    resetTrigger,
    setResetTrigger
}) => {
    const navigate = useNavigate()
    const dataSource = AddedItems?.added
    const [filterData, setFilterData] = useState(dataSource);
    const [title, setTitle] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [showItemsPanel, setShowItemsPannel] = useState(false);

    const [checkedAll, setCheckedAll] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
    const [showUploadButton, setShowUploadButton] = useState<boolean>(false)
    const [showUpdateExpModal, setShowUpdateExpModal] = useState<boolean>(false)
    const { experiment, variant, setExperiment, setVariant } = usePujaStore()
    const { networkInstance, countryState, app } = useAppContext()
    const [searchParams, setSearchParams] = useSearchParams()
    const store_id = searchParams.get('store_id') || ''

    useEffect(() => {
        setExperiment(undefined)
        setVariant(undefined)
    }, [])

    const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi

    useEffect(() => {
        setFilterData(dataSource);
        setTitle('')
        setDisplayName('')
    }, [dataSource])

    const filterByTitleInput = () => {
        return <Input
            placeholder="Search Title"
            size='small'
            value={title}
            onChange={e => {
                const currValue = e.target.value;
                setTitle(currValue);
            }}
        />
    };

    const filterByDisplayNameInput = () => {
        return <Input
            size='small'
            placeholder="Search Display name"
            value={displayName}
            onChange={e => {
                const currValue = e.target.value;
                setDisplayName(currValue);
            }}
        />
    };

    useEffect(() => {
        const filterByTitle = dataSource
            .filter((entry: any) => {
                return entry.title.toLowerCase().includes(title.toLowerCase())
            })
            .filter((entry: any) => {
                if (displayName === "") {
                    return true;
                }
                return entry.display_name?.toLowerCase().includes(displayName.toLowerCase())
            });
        if (title.trim() === "" && displayName.trim() === "") {
            setFilterData(dataSource);
            return
        }
        setFilterData(filterByTitle);
    }, [title, displayName, dataSource])



    const handleSelectAll = (checked: boolean) => {
        setCheckedAll(checked);
        setShowUploadButton(true)
        if (checked) {
            setSelectedRowKeys(filterData.map((item) => item.id));
        } else {
            setSelectedRowKeys([]);
        }
    };

    const handleRowSelection = (key: number, checked: boolean) => {
        setCheckedAll(false);
        setSelectedRowKeys((prev: any) =>
            checked ? [...prev, key] : prev.filter((rowKey: number) => rowKey !== key)
        );
    };
    useEffect(() => {
        if (selectedRowKeys?.length > 0) {
            setShowUploadButton(true)
        } else {
            setShowUploadButton(false)
        }
    }, [selectedRowKeys, checkedAll])

    const shouldShowCheckboxColumn = filterData.some(row => row.exp_linked?.toString() !== 'true');

    const columns: any = [
        ...(experiment && shouldShowCheckboxColumn
            ? [
                {
                    title: (
                        <Checkbox
                            indeterminate={
                                !checkedAll &&
                                selectedRowKeys.length > 0 &&
                                selectedRowKeys.length < filterData.length
                            }
                            checked={checkedAll}
                            onChange={(e) => handleSelectAll(e.target.checked)}
                        />
                    ),
                    render: (_: any, record: any) =>
                        record?.exp_linked?.toString() !== 'true' ? (
                            <Checkbox
                                checked={checkedAll || selectedRowKeys.includes(record.id)}
                                onChange={(e) =>
                                    handleRowSelection(record.id, e?.target?.checked)
                                }
                            />
                        ) : null, // Return null if the condition is not met
                    width: "10%",
                },
            ]
            : []),
        {
            title: 'Inventory Id',
            dataIndex: 'id',
            key: 'inventory_id',
            width: '15%',
            ellipsis: true,
            render: (store_id: string) => (
                <Tooltip placement="topLeft" title={store_id}>
                    {store_id}
                </Tooltip>
            ),
        },
        {
            title: <div> Title <br></br> {filterByTitleInput()}</div>,
            dataIndex: 'title',
            key: 'title',
            width: '15%',
            ellipsis: true,
            render: (title: string) => (
                <Tooltip placement="topLeft" title={title}>
                    {title}
                </Tooltip>
            ),
        },
        {
            title: 'Batch',
            dataIndex: 'batch_size',
            key: 'batch_size',
            width: '7%',
        },
        {
            title: 'Quantities ',
            dataIndex: 'quantities',
            key: 'quantities',
            width: '10%',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            width: '8%',
            render: (text: number) => {
                if (text) {
                    return (
                        <Tag color="green" key={text} style={{ width: '100%', textAlign: 'center' }}>
                            {text.toString()}
                        </Tag>
                    );
                }
                return "-";
            },
        },
        {
            title: 'Position',
            dataIndex: 'position',
            key: 'position',
            width: '8%',
        },
        {
            title: 'Item type',
            dataIndex: 'item_type',
            key: 'item_type',
            width: '10%',
        },
        {
            title: 'Recommended',
            dataIndex: 'recommended',
            key: 'recommended',
            width: '12%',
            render: (text: boolean, record: any) => {
                if (text) {
                    return (
                        <Badge.Ribbon
                            text={text && 'Recommended'}
                            color="green"
                            style={{ marginTop: '-20px' }}
                        />
                    );
                } else {
                    return <>-</>;
                }
            },
        },
        {
            title: 'Campaign Ids',
            dataIndex: 'campaigns',
            key: 'campaigns',
            width: '20%',
            render: (text: string, record: any) => {
                if (record?.campaignList?.length) {
                    return (
                        <div style={{ display: 'flex', flexWrap: 'wrap', rowGap: '8px' }}>
                            {record?.campaignList?.map((campaign: string) => {
                                return (
                                    <Tag color="blue" key={campaign}>
                                        {campaign}
                                    </Tag>
                                );
                            })}
                        </div>
                    );
                } else {
                    return <p>-</p>;
                }
            },
        },
        {
            title: 'Experiments Linked',
            dataIndex: 'exp_linked',
            key: 'exp_linked',
            width: '14%',
            render: (text: boolean, record: any) => {
                return record?.exp_linked ? <Tag color="green"> {record?.exp_linked?.toString?.()}</Tag > : <Tag color='red'> {record?.exp_linked?.toString?.()}</Tag>
            },
        },
        {
            title: 'Status',
            dataIndex: 'active',
            key: 'active',
            width: '7%',
            render: (text: string, record: any) => {
                return (
                    <Badge.Ribbon
                        text={record.active ? 'Active' : 'InActive'}
                        color={record?.active ? 'green' : 'red'}
                        style={{ marginTop: '-20px' }}
                    />
                );
            },
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            width: '6%',
            render: (text: string, record: any) => {
                return (
                    <Button
                        onClick={() => {
                            triggerEdit(record);
                        }}
                        type="link"
                        color="cyan"
                        style={{ cursor: 'pointer' }}
                    >
                        Edit
                    </Button>
                );
            },
            fixed: 'right',
        },
    ];

    const handleUpdateBtnClick = () => {
        setShowUpdateExpModal(true)
    }
    const closeModal = () => {
        setShowUpdateExpModal(false)
    }

    useEffect(() => {
        if (resetTrigger) {
            setCheckedAll(false)
            setSelectedRowKeys([])
            setResetTrigger(false)
        }
    }, [resetTrigger]);

    useEffect(() => {
        setCheckedAll(false)
        setSelectedRowKeys([])
        setResetTrigger(false)
    }, [experiment, variant]);

    const updateInventories = async () => {
        try {
            const updateResponse = await monetisationPujaApi.storesApi.updateInventories(
                store_id,
                countryState.countryCode,
                experiment as string,
                variant || "VARIANT",
                { inventory_ids: selectedRowKeys },
            );
            console.log(updateResponse);
            setShowUpdateExpModal(false);
            message.success("Inventories Updated Successfully")
            setCheckedAll(false)
            setSelectedRowKeys([])
            getStoreProductList()
        } catch (error) {
            console.error("Failed to update inventories:", error);
            setShowUpdateExpModal(false);
            message.error("Failed to update inventories")
            setCheckedAll(false)
            setSelectedRowKeys([])
            getStoreProductList()
        }
    };

    return (
        <div>
            <Row justify='space-between'>
                <Col style={{ paddingBottom: '10px' }}>
                    <Button onClick={() => setShowItemsPannel(!showItemsPanel)} type='dashed' size='small' style={{ borderColor: 'black' }}>
                        Show Items Pannel
                    </Button>
                </Col>
                <Col style={{ float: 'right' }}>
                    <SortAscendingOutlined /> Sort by : &nbsp;
                    <Select placeholder="Sort by price, position ..." value={sortBy} allowClear size='small' style={{ width: "200px" }} onChange={(value) => { updateSortBy(value) }}>
                        <Select.Option value="price"> Price</Select.Option>
                        <Select.Option value="position"> Position</Select.Option>
                        <Select.Option value="created_at"> Created at</Select.Option>
                    </Select>
                </Col>
            </Row>
            <Container style={{ width: '100%' }}>

                <Space direction={'vertical'} style={{ width: '100%' }}></Space>
                <Drawer
                    width={600}
                    visible={showItemsPanel}
                    placement='left'
                    closable
                    onClose={() => setShowItemsPannel(!showItemsPanel)}
                >
                    <Col span={24}>
                        <Title level={4}>All Items</Title>
                        <Row>
                            <Col span={4}>
                                <Button onClick={createItem} type='link' size='small'>
                                    <PlusSquareOutlined /> Add
                                </Button>
                            </Col>
                        </Row>
                        <ListAllItems>
                            {productList?.map((item: Product) => (
                                <OfferingItem formItems={item} key={item.id} added={added} />
                            ))}
                        </ListAllItems>
                    </Col>
                </Drawer>
                <BodyContainer>
                    {filterData && <Table
                        dataSource={filterData}
                        columns={columns}
                        pagination={paginationParams}
                        onChange={handleTableChange}
                        loading={{
                            spinning: loading,
                            indicator: <Spin indicator={<CustomLoader />} />,
                        }}
                        scroll={{ x: 1600 }}
                    />}

                </BodyContainer>
            </Container >
            <Col span={24}>
                <LabelRightAlign>
                    <Space>
                        <Button type='primary' onClick={() => navigate("/monetisation/puja/temples")}>
                            Cancel
                        </Button>
                        {currentStep > 0 && (
                            <Button type='primary' onClick={previousHandler}>
                                Previous
                            </Button>
                        )}
                        {currentStep !== 2 && (
                            <Button type='primary' onClick={nextHandler}>
                                Next
                            </Button>
                        )}
                    </Space>
                </LabelRightAlign>
            </Col>
            {showUploadButton && <Button
                type="primary"
                shape="circle"
                icon={<UploadOutlined />}
                size='large'
                style={{
                    position: 'fixed', bottom: 40, right: 30,
                    zIndex: 100,
                    height: '50px',
                    width: '50px'
                }}
                onClick={handleUpdateBtnClick}
            />}
            <Modal visible={showUpdateExpModal} closable={true} onCancel={closeModal} footer={[
                <Button key='cancel' onClick={closeModal}>
                    Cancel
                </Button>,
                <Button key='done' type='primary' onClick={() => updateInventories()}>
                    Update
                </Button>
            ]}>
                <div>
                    Are you sure you want to update the selected Inventories?
                </div>
            </Modal>
        </div >
    )

}

export default InventoryList
