export const parseOrders = (orderList: any, serviceType: string) => {
  if (!orderList?.orders) {
    return []
  }
  if (serviceType === 'offerings') {
    let offeringOrders: any[] = []
    orderList?.orders?.map((order: any) => {
      const {
        booking_whatsapp_number,
        booking_member_data,
        is_opted_for_prasad,
        booking_address,
        ...rest
      } = order
      offeringOrders?.push(rest)
    })
    const parsedOfferingsOrderData = orderList?.orders?.map((order: any) => ({
      ...order,
      items: order?.items?.map((item: any) => ({
        ...item,
        meta_data: {
          ...item?.meta_data,
          video_url: [item?.meta_data?.video_url],
        },
      })),
    }))
    return parsedOfferingsOrderData
  } else {
    let trimmedMobileNumber: string
    let trimmedWhatsAppNumber: string

    const parsedOrderData = orderList?.orders.map((order: any) => {
      const hasDeliverable = order?.add_ons?.some(
        (obj: any) => obj.deliverable === true,
      )
      const mobile = order?.booking_member_data?.[0]?.mobile_number ?? ''
      const whatsAppNumber = order?.booking_whatsapp_number ?? ''
      if (mobile?.length > 10 && mobile?.includes('+91')) {
        trimmedMobileNumber = mobile?.replace(/^\+91/, '')
      } else {
        trimmedMobileNumber = mobile
      }
      if (whatsAppNumber?.length > 10 && whatsAppNumber?.includes('+91')) {
        trimmedWhatsAppNumber = whatsAppNumber?.replace(/^\+91/, '')
      } else {
        trimmedWhatsAppNumber = whatsAppNumber
      }
      const bookingMemberData = order?.booking_member_data
      const parsedMemberData = bookingMemberData
        ? [
          {
            ...bookingMemberData[0],
            mobile_number: trimmedMobileNumber,
            whatsapp_number: trimmedWhatsAppNumber,
          },
          ...bookingMemberData.slice(1),
        ]
        : null
      return {
        ...order,
        is_deliverable: hasDeliverable,
        booking_whatsapp_number: trimmedWhatsAppNumber,
        booking_member_data: parsedMemberData,
        items: order?.items?.map((item: any) => ({
          ...item,
          meta_data: {
            ...item?.meta_data,
            video_url: [item?.meta_data?.video_url],
          },
        })),
      }
    })
    return parsedOrderData
  }
}

export const actionMapping: any = {
  offeringUserName: 'UPDATE_OFFERING_USER_NAME',
  memberData: 'UPDATE_BOOKING_MEMBER_DATA',
  sku: 'UPDATE_INVENTORY_VIDEO',
  address: 'UPDATE_PRASAD_DETAILS',
  refund: 'REFUND',
  whatsappNumber: 'UPDATE_WHATSAPP_NUMBER',
}

export const orderPayload = (
  order: any,
  action: string,
  country_code?: string,
) => {
  switch (action) {
    case 'offeringUserName':
      return {
        offering_user_name: order?.offering_user_name,
      }
    case 'sku':
      return {
        inventory_video_data: {
          inventory_id: order?.id,
          media: {
            media_type: order?.meta_data?.media_type,
            media_url: order?.meta_data?.video_url?.[0],
          },
        },
      }
    case 'memberData':
      if (country_code === 'IN' && order?.booking_member_data?.[0]?.same_as_mobile_number) {
        if (
          order?.booking_member_data?.[0]?.mobile_number !==
          order?.booking_member_data?.[0]?.whatsapp_number
        ) {
          return {
            error: {
              status: true,
              code: 'number_mismatch',
            },
          }
        }
      }
      return {
        booking_member_data: order?.booking_member_data
          ?.filter((item: any) => item?.full_name !== '')
          ?.map((member: any, index: number) => {
            if (index === 0) {
              if (country_code === 'IN') {
                return {
                  full_name: member?.full_name,
                  gotra: member?.gotra,
                  mobile_number: `+91${member?.mobile_number}`,
                  whatsapp_number: `+91${member?.whatsapp_number}`,
                  same_as_mobile_number: member?.same_as_mobile_number,
                }
              } else {
                return {
                  full_name: member?.full_name,
                  gotra: member?.gotra,
                }
              }
            } else {
              return {
                full_name: member?.full_name,
              }
            }
          }),
      }
    case 'address':
      if (order?.is_opted_for_prasad) {
        return {
          prasad_details: {
            is_opted_for_prasad: order?.is_opted_for_prasad,
            address: order?.booking_address,
          },
        }
      }
      if (order?.is_deliverable) {
        return {
          prasad_details: {
            address: order?.booking_address,
          },
          is_deliverable: order?.is_deliverable,
        }
      }
      break
    case 'whatsappNumber':
      return {
        whatsapp_number: `+91${order?.booking_whatsapp_number}`,
      }
  }
}

export const orderStatusFilterOptions = [
  {
    label: 'Booked',
    value: 'booked'
  },
  {
    label: 'Refunded',
    value: 'refunded'
  },
  {
    label: 'Completed',
    value: 'completed'
  },
  {
    label: 'Live',
    value: 'live'
  },
  {
    label: 'Rescheduled',
    value: 'rescheduled'
  },
  {
    label: 'To Be Rescheduled',
    value: 'to_be_rescheduled'
  },
  {
    label: 'Refund Initiated',
    value: 'refund_initiated'
  },
  {
    label: 'Refund Failed',
    value: 'refund_failed'
  },
  {
    label: 'Manual Review',
    value: 'refund_manual_review'
  },
]

export const nonReschedulableStatuses = [
  "rescheduled",
  "refunded",
  "refund_initiated",
  "refund_failed",
  "refund_manual_review",
];

export const disabledStatuses = [
  "refunded",
  "refund_initiated",
  "refund_failed",
  "refund_manual_review",
];

export const refundTypes = [
  { value: 'normal', ButtonName: 'Normal' },
  { value: 'instant', ButtonName: 'Instant' }
];