import { Button, Col, DatePicker, Drawer, Form, Input, message, Row, Switch } from "antd";
import { Shrine } from "@a4b/api/src/modules/Temple/types";
import { useForm } from 'antd/lib/form/Form'
import { useCallback, useEffect, useState } from "react";
import { useAppContext } from "@/components/AppContext";
import GodItemForm from "../../../god-items/god-item-form";
import { parseGodItemScheduleResponse, prepareGodItemSchedulePayload } from "../../utils/helper";

interface GodItemsScheduleFormProps {
    formData: Shrine.GodItemSchedule,
    showForm: boolean
    isEdit: boolean
    fetchGodItemSchedulesCallBack(): void
    handleCloseForm: () => void
}

export const ITEM_FORM_DEFAULT_FEILDS_SET = new Set(['code', 'type', 'access_type', 'thumbnail', 'attachment', 'coins_cost', 'validity_period', 'available_for_all_gods', 'days_active', 'schedule_at', 'active']);

const GodItemsScheduleForm: React.FC<GodItemsScheduleFormProps> = ({ formData, isEdit, showForm, fetchGodItemSchedulesCallBack, handleCloseForm }) => {
    const [godItemScheduleForm] = useForm()
    const { networkInstance } = useAppContext()
    const { shrineApi } = networkInstance.clientWithHeaders
    const [loading, setLoading] = useState(false)
    const [showGodItemScheduleForm, setShowGodItemScheduleForm] = useState<boolean>(false)
    const [defaultItem, setDefaultItem] = useState<undefined | Shrine.Item>()

    const getGodItemById = useCallback(async (ItemId: string): Promise<Shrine.Item | undefined> => {
        setLoading(true);
        try {
            const godItemResponse = await shrineApi.getItem(ItemId)
            const godItem: Shrine.Item | undefined = godItemResponse?.data?.data?.item
            message.success('Fetched item successfully', 2)
            setDefaultItem(godItem)
            return godItem
        } catch (error: any) {
            console.log({ error })
            godItemScheduleForm.resetFields()
            message.error('Error while fetching item: ' + error?.response?.data?.error?.message, 3)
            return undefined
        } finally {
            setLoading(false);
        }
    }, [godItemScheduleForm, shrineApi])

    const handleParseData = useCallback((data: any) => {
        try {
            const parsedData = parseGodItemScheduleResponse(data)
            godItemScheduleForm.setFieldsValue(parsedData)
            setShowGodItemScheduleForm(true)
        } catch (e) {
            console.log((e))
            message.error('Error while parsing Item: ', 3)
        }
    }, [godItemScheduleForm])


    const fetchGodItem = async (id: string) => {
        const item = await getGodItemById(id)
        handleParseData(item)
    }

    useEffect(() => {
        if (!isEdit) {
            return
        }
        handleParseData(formData)
    }, [formData, godItemScheduleForm, handleParseData, isEdit])

    const createGodItemSchedule = async (payload: Shrine.CreateItemSchedulePayload) => {
        try {
            await shrineApi.createGodItemSchedule(payload)
            message.success({ content: "Item Schedule Created", duration: 3 });
            handleCloseForm()
            setTimeout(() => {
                fetchGodItemSchedulesCallBack()
            }, 500)
            godItemScheduleForm?.resetFields();
        } catch (error: any) {
            message.error({ content: `Error while creating Item Schedule — ${error?.response?.status?.toString()}`, duration: 3 });
            console.log({ error });
        }
    }

    const updateGodItemSchedule = async (payload: Shrine.CreateItemSchedulePayload) => {
        if (!formData?.id) {
            message.error('Schedule ID not found')
            return
        }

        try {
            await shrineApi.updateGodItemSchedule(formData?.id, payload)
            message.success({ content: "Item Schedule Updated", duration: 3 })
            handleCloseForm()
            setTimeout(() => {
                fetchGodItemSchedulesCallBack()
            }, 500)
            godItemScheduleForm?.resetFields()
        } catch (error: any) {
            message.error({ content: `Error while updating Item Schedule — ${error?.response?.status?.toString()}`, duration: 3 });
            console.log({ error });
        }
    }

    const handleFormSubmit = (values: any) => {
        console.log('form submit', values)
        // Prepare payload
        const itemId: string = defaultItem?.id ?? formData?.id
        const payload = prepareGodItemSchedulePayload(values, itemId)
        console.log('payload', payload)

        if (!payload) {
            message.error('Item ID not found while preparing payload')
            return
        }

        if (isEdit) {
            updateGodItemSchedule(payload!)
        } else {
            createGodItemSchedule(payload!)
        }
    }

    return (
        <Drawer
            title={
                <Col style={{ width: '100%' }}>
                    <span>{'Item schedule'}</span>
                </Col>
            }
            placement='right'
            onClose={() => {
                handleCloseForm()
                godItemScheduleForm?.resetFields()
            }}
            visible={showForm}
            destroyOnClose={true}
            width={500}
        >
            {!isEdit && <Row style={{ marginBottom: '20px', width: '100%' }}>
                <Input.Search
                    placeholder="Input God item ID"
                    loading={loading}
                    enterButton
                    onSearch={(value) => {
                        if (value) {
                            fetchGodItem(value)
                        } else {
                            message.warning('Please enter an God Item ID', 3)
                        }
                    }}
                />
            </Row>}
            {showGodItemScheduleForm && <Form
                form={godItemScheduleForm}
                onFinish={handleFormSubmit}
                layout="vertical"
                style={{ width: '100%' }}
            >
                {/* Existing God Item form - which has main God Item form fields */}
                <GodItemForm form={godItemScheduleForm} editItem={true} showSubmit={false} />
                <Row justify="space-between" style={{ width: '100%' }}>
                    <Form.Item
                        label="Schedule At"
                        name="schedule_at"
                        rules={[{ required: true, message: 'Please select a date and time!' }]}
                    >
                        <DatePicker disabled={isEdit} showTime format="YYYY-MM-DD HH:mm:ss" />
                    </Form.Item>
                    <Form.Item
                        label="Cancel Schedule"
                        name="cancel_schedule"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item
                        label="Active"
                        name="active"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                </Row>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
            }
        </Drawer>
    )
}

export default GodItemsScheduleForm;