import { useAppContext } from '@/components/AppContext'
import { YODA_CDN_URL, YODA_PRESIGN_URL } from '@/utils/constants'
import { Modal, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import React, { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import AssignOfferingStepPage from '../components/inventories/AssignOfferingStepPage'
import ManageInventories from '../components/inventories/ManageInventories'
import ConfirmationPopup from '@a4b/ui/src/modules/monetisation/molecules/ConfirmationPopup'

import { LanguageState, Product } from '@a4b/api/src/modules/Monetisation/Offerings/types'

import {
  formatInventoryForEdit,
  formatInventoryForEditAdd,
  parseStoreProductList,
} from '../utils/helper'
import { TablePaginationConfig } from 'antd/es/table'
import Error from '@/components/Error'

interface Props {
  isEditJourney: boolean
  currentStep: number
  storeId: string
  nextHandler: (store_id?: string) => void
  previousHandler: () => void
  languageState: LanguageState
}
type AddedItems = { added: Product[] }
const AssigningOfferStep: React.FC<Props> = ({
  currentStep,
  isEditJourney,
  storeId,
  previousHandler,
  nextHandler,
  languageState
}) => {
  const [formInstance] = useForm()
  const { networkInstance, countryState, userProfile } = useAppContext()
  const { monetisationOfferingsApi } = networkInstance.clientWithHeaders
  const [productList, setProductList] = useState<Product[]>([])
  const [addedList, setAddedList] = useState<AddedItems>({
    added: [],
  })
  const [searchParams, setSearchParams] = useSearchParams()
  const [showItemCreation, setShowItemCreation] = useState(false)
  const [campaignList, setCampaignList] = useState<any>()
  const [filters, setFilters] = useState({ item_type: 'item' })

  const [showInventoryCreation, setShowInventoryCreation] = useState<boolean>(false)
  const [isEditJourneyForm, setIsEditJourneyForm] = useState<boolean>(false)
  const [editInventory, setEditInventory] = useState<any>()
  const [createInventoryForm] = useForm()
  const [paginationParams, setPaginationParams] =
    useState<TablePaginationConfig>({
      current: 1,
      pageSize: 50,
    })

  const [nameListId, setNameListId] = useState<string>('')
  const [inventoryData, setInventoryData] = useState<any>()
  const [visible, setVisible] = useState<boolean>(false)
  const [errorPage, setErrorPage] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [enableEditPosition, setEnableEditPosition] = useState(false)
  const [initialValues, setInitialValues] = useState<any>()

  const getCampaignList = async () => {
    try {
      const CampaignListResponse = await monetisationOfferingsApi.campaignsAPi.getCampaignList({
        limit: 1000,
        offset: 0,
      }, true)
      return CampaignListResponse
    } catch (error) {
      message.error('Error while fetching campaign list')
      return []
    }
  }

  const updateSortBy = (sortBy?: ('price' | 'position' | 'created_at')) => {
    if (sortBy) {
      searchParams.set('sort_by', sortBy)
    } else {
      searchParams.delete('sort_by')
    }
    setSearchParams(searchParams)
  }

  const getStoreList = async () => {
    try {
      const storeList = await monetisationOfferingsApi.storesApi.getStoreList(null, 'dropdown', countryState?.countryCode)
      return storeList?.data?.data?.store_list.sort((item1: any, item2: any) => item1.position - item2.position)
    } catch (error) {
      message.error({
        content: 'Error while fetching store list',
        duration: 3,
      })
      return []
    }
  }

  const getStoreProductList = useCallback(
    async (availableProductList?: any[]) => {
      if (!storeId) {
        return
      }
      setLoading(true)
      try {
        const productListResponse =
          await monetisationOfferingsApi.storesApi.getProductList(
            storeId,
            countryState?.countryCode,
            searchParams.get('sort_by') as ("price" | "position" | "created_at")
          )

        const campaignList: any = await getCampaignList()
        const storeList: any = await getStoreList()
        const parsedProductData = parseStoreProductList(
          productListResponse?.data?.data, campaignList?.data?.data?.campaigns,
          storeList,
          languageState
        )
        setCampaignList(campaignList?.data?.data?.campaigns)
        const positionData: any = {}
        parsedProductData?.map(
          (item: any) => (positionData[item.id] = item?.position),
        )
        console.log({ parsedProductData })
        setInitialValues(positionData)
        formInstance.setFieldsValue(positionData)
        setInventoryData(parsedProductData)
        removeAddeditemFromProductList(
          availableProductList || productList,
          parsedProductData,
        )
        setAddedList({ added: parsedProductData })
        createInventoryForm?.resetFields()
        createInventoryForm.setFieldsValue({ added: parsedProductData })
        setLoading(false)
      } catch (error) {

        console.log(error)
        message.error('Error while fetching store products', 3)
      }
    },
    [
      isEditJourney,
      monetisationOfferingsApi.storesApi,
      createInventoryForm,
      searchParams,
      countryState?.countryCode
    ],
  )

  const enableUpdate = (status: boolean) => {
    setEnableEditPosition(status)
  }

  const updatePosition = async () => {
    const formValues = formInstance.getFieldsValue()
    const updatedFields = inventoryData.map((inventory: any) => {
      const updatedPosition = formValues[inventory.id]
      if (inventory.position !== updatedPosition && updatedPosition !== undefined && updatedPosition !== null) {
        return { inventory_id: inventory.id, position: updatedPosition }
      }
      return null
    }).filter((item: any) => item !== null)

    if (updatedFields.length === 0) {
      message.info('No inventory positions were changed.')
      return
    }

    try {
      await monetisationOfferingsApi.storesApi.updateInventoryPatch("UPDATE_INVENTORY_POSITIONS",
        {
          user_email: userProfile?.email,
          inventories: updatedFields
        }
      )
      setEnableEditPosition(false)
      message.success({
        content: 'Inventory position updated sucessfully',
        duration: 3,
        key: 'update_position',
      })
      setTimeout(() => {
        getStoreProductList()
      }, 500)
    } catch (error) {
      setEnableEditPosition(false)

      message.error({
        content: 'Error while updating inventory position',
        duration: 2,
        key: 'update_position',
      })

      console.log(error)
    }

  }

  const getProductList = useCallback(async () => {
    if (currentStep !== 1) {
      return
    }
    try {
      const productList = await monetisationOfferingsApi.productApi.getProductList({
        limit: 500,
        offset: 0,
      })
      let filteredData;
      filteredData = productList?.data?.data
      if (searchParams.get('service_type') === 'pooja') {
        filteredData = productList?.data?.data?.filter((item: any) => item?.item_type !== "sankalp")
        setProductList(filteredData || [])
        if (currentStep === 1) {
          getStoreProductList(filteredData)
        }
      } else {
        if (searchParams.get('store_type') === 'mixed') {
          filteredData = productList?.data?.data?.filter((item: any) => item?.item_type !== "pooja")
        } else {
          filteredData = productList?.data?.data?.filter((item: any) => item?.item_type !== "pooja" && item?.item_type !== "combo_item" && item?.item_type !== "combo_sankalp")
        }
        setProductList(filteredData || [])
        if (currentStep === 1) {
          getStoreProductList(filteredData)
        }
      }
    } catch (error) {
      setErrorPage(true)
      message.error({
        content: 'Error while fetching product list',
        duration: 3,
      })
      console.log(error)
    }
  }, [currentStep, getStoreProductList, monetisationOfferingsApi.productApi, searchParams])

  useEffect(() => {
    if (currentStep === 1) {
      searchParams.set("language_code", languageState?.monetisationLanguage)
      searchParams.set("country_code", countryState?.countryCode)
      setSearchParams(searchParams)
      getProductList()
    }
  }, [storeId, currentStep, languageState.monetisationLanguage, getProductList])

  useEffect(() => {
    if (searchParams.get('service_type') === 'pooja') {
      setFilters({ item_type: searchParams.get('service_type') || '' })
    }
    createInventoryForm.setFieldsValue({ service_type: searchParams.get('service_type') })
  }, [searchParams])

  const removeAddeditemFromProductList = (
    productList: any[],
    parsedProductData: any[],
  ) => {
    if (!productList.length || !parsedProductData.length) {
      return
    }

    const newProductList = productList.filter(item => {
      return !parsedProductData.find(addedItem => {
        return addedItem.master_product_id === item.id
      })
    })

    setProductList(newProductList)
  }

  const addItem = async (item: Product) => {
    let addNewItem: any = {}
    addNewItem = formatInventoryForEditAdd(item, countryState?.countryCode, languageState?.monetisationLanguage)
    if (filters?.item_type === "pooja") {
      addNewItem['member_size'] = 1;
    }
    try {
      const store_id = searchParams.get('store_id') || ''
      await monetisationOfferingsApi.storesApi.createInventoryItem(store_id, addNewItem)
      setTimeout(() => {
        window.location.reload()
      }, 100)
      const restItems = productList.filter(
        (templeItem: Product) => templeItem.id !== item.id,
      )
      setProductList([...restItems])
      getStoreProductList()
    } catch (error) {
      console.log(error)
    }
  }

  const showItemForm = () => {
    setShowItemCreation(true)
  }

  const updateInventoryItemApi = async () => {
    try {
      await monetisationOfferingsApi.storesApi.updateInventoryItem(
        nameListId,
        inventoryData,
      )
      closeItemCreationForm()
      setTimeout(() => {
        getStoreProductList()
      }, 500)
      message.success({ content: 'Item Updated Successfully', duration: 2 })
    } catch (error) {
      console.log('error occured while updating inventory item', error)
      message.error({
        content: 'Error occured while updating inventory item',
        duration: 2,
      })
    }
  }

  const archiveInventory = async (nameListId: string) => {
    try {
      await monetisationOfferingsApi.storesApi.archiveInventoryItem(nameListId)
      setTimeout(() => {
        getStoreProductList()
      }, 500)
      message.success({ content: 'Item Archived Successfully', duration: 2 })
    } catch (error) {
      console.log('error occured while archiving inventory', error)
      message.error({
        content: 'Error occured while archiving inventory',
        duration: 2,
      })
    }
  }

  const handleOk = () => {
    setVisible(false)
    updateInventoryItemApi()
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const updateRecord = async (nameList: any) => {
    const formData = createInventoryForm.getFieldValue(nameList)

    if (filters?.item_type !== "pooja" &&
      (!formData?.intro_video_url?.length ||
        !formData?.outro_video_url?.length) &&
      formData?.active
    ) {
      message.error(
        'Please upload intro and outro video before enabling the inventory.',
      )
      return
    }
    const parsedData = formatInventoryForEdit(
      nameList,
      campaignList,
      languageState.monetisationLanguage,
      countryState.countryCode,
      searchParams?.get('service_type') as string,
      searchParams?.get('store_type') as string,
      searchParams?.get('process_date')
    )

    setNameListId(nameList.id)
    setInventoryData(parsedData)
    setVisible(true)
  }
  const redirectToItemCreation = () => {
    window.location.href = '/monetisation/offerings/items?isCreateFlow=true'
  }

  const updateComboSKUConfig = (record: any) => {
    if (!record?.combo_sku_config) return record

    const comboSKUConfigMap = record.combo_sku_config
    const selectedSKUKeys = Object.keys(comboSKUConfigMap)

    return { ...record, combo_sku_config: selectedSKUKeys }
}

  const triggerEdit = (record: any) => {
    const updatedRecord = updateComboSKUConfig(record)
    if ((record?.item_type === 'pooja') && record?.additional_info?.error) {
      message.warning('Error in Additional Info HTML Snippet', 2)
    }
    setEditInventory(updatedRecord)
    setIsEditJourneyForm(true)
    setShowInventoryCreation(true)
  }

  const closeItemCreationForm = () => {
    setShowInventoryCreation(false)
    isEditJourneyForm && setIsEditJourneyForm(false)
    setEditInventory(undefined)
  }

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setPaginationParams({
      ...pagination,
    })
  }
  return (
    <>

      {errorPage ?
        <div style={{ height: '100vh' }}>
          <Error text='Error while fetching Inventories!' />
        </div> :
        <AssignOfferingStepPage
          AddedItems={addedList}
          triggerEdit={triggerEdit}
          productList={productList}
          added={addItem}
          createItem={showItemForm}
          currentStep={currentStep}
          previousHandler={previousHandler}
          nextHandler={() => nextHandler()}
          paginationParams={paginationParams}
          handleTableChange={handleTableChange}
          loading={loading}
          sortBy={searchParams.get('sort_by') as ("price" | "position" | "created_at")}
          updateSortBy={updateSortBy}
          archiveInventory={archiveInventory}
          enableEditPosition={enableEditPosition}
          enableUpdate={enableUpdate}
          updatePosition={updatePosition}
          formInstance={formInstance}
          initialValues={initialValues}
        />
      }
      <ManageInventories
        showItemCreation={showInventoryCreation}
        closeItemCreation={closeItemCreationForm}
        form={createInventoryForm}
        initialValues={editInventory}
        updateRecord={updateRecord}
        cdnUrl={YODA_CDN_URL}
        preSignUrl={YODA_PRESIGN_URL}
        service_type={searchParams.get('service_type') || ""}
        addedItems={addedList}
      />

      <Modal
        title='Basic Modal'
        visible={showItemCreation}
        onOk={redirectToItemCreation}
        onCancel={() => setShowItemCreation(false)}
      >
        <p>Are you sure you want to switch to item creation flow ?</p>
      </Modal>

      {inventoryData &&
        <ConfirmationPopup
          storeData={inventoryData}
          visible={visible}
          handleOk={handleOk}
          handleCancel={handleCancel}
          title={'Inventory'}
        />
      }
    </>
  )
}

export default AssigningOfferStep
