/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Radio,
  Row,
  Select,
  Space,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import moment from 'moment'
import styled from 'styled-components'

import React, { useEffect } from 'react'
import S3Upload, { AllowedFormats } from '@a4b/ui/src/modules/monetisation/molecules/S3Upload'
import { SUPPORTED_LANGUAGES_LIST } from '@a4b/ui/src/modules/admin/constants'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { YODA_CDN_URL, YODA_PRESIGN_URL } from '@/utils/constants'



const { RangePicker } = DatePicker
const { Option } = Select
interface Props {
  onClose: () => void
  visible: boolean
  apiBaseUrl: string
  formSubmithandler: (values: any) => void
  form: any
  initialValues: any
  isEditJourney: boolean
  isCloneJourney?: boolean
  headers: any
  url: string
  onFormChange: (values: any, allFields: any) => void
  deleteStory: (id: string) => Promise<void>
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  &:before {
    content: '${(props: any) => props.title}';
    margin-top: -35px;
    margin-left: 12px;
    width: fit-content;
    background: #fff;
    padding: 0px 12px;
    font-size: 18px;
  }
  margin-bottom: 5px;
  margin-right: 5px;
  border: 1px solid #a69494;
  border-radius: 5px;
  padding: 20px;
  margin-top: 40px;
  box-sizing: border-box;
`
const LIVE_SUPPORTED_LANGUAGES = SUPPORTED_LANGUAGES_LIST.filter((lang) => lang.state === 'live')

const StoryCreation: React.FC<Props> = ({
  onClose,
  visible,
  apiBaseUrl,
  formSubmithandler,
  form,
  initialValues,
  isEditJourney,
  isCloneJourney,
  headers,
  url,
  onFormChange,
  deleteStory
}) => {
  const startAndEndDateValidator = async (rule: any, value: any) => {
    if (!value) return Promise.reject('Start and end date required.')
    if (value && value.length < 2)
      return Promise.reject('Both Start and End date required.')
    const [from, to] = value
    const isGreater = to.isSameOrBefore(from)
    if (isGreater) {
      return Promise.reject('End date should be greater than Start date.')
    }
  }

  const selectedLanguages = Form.useWatch('supported_languages', form) || ['en'];

  const attechments = Form.useWatch('attachment', form);
  let attechmentCount = 0;
  if (attechments) {
    Object.keys(attechments).forEach((key) => {
      if (attechments[key]?.attachment?.length > 0) {
        attechmentCount += 1;
      }
    })
  }
  const isAttechment = attechmentCount === 1;
  const applyAttechmentToAllTheLanguages = () => {
    let attechment = {};
    Object.keys(attechments).forEach((key) => {
      if (attechments[key]?.attachment?.length > 0) {
        attechment = attechments[key];
      }
    });
    const updatedAttechments = { ...attechments };
    Object.keys(attechments).forEach((key) => {
      if (!attechments[key]?.attachment || attechments[key]?.attachment?.length === 0) {
        updatedAttechments[key] = attechment;
      }
    });
    form.setFieldsValue({ attachment: updatedAttechments });
  };

  const ctaDeeplink = Form.useWatch(['cta', 'deeplink'], form);
  return (
    <Drawer
      title='Create New Story'
      placement='right'
      width={1300}
      onClose={onClose}
      visible={visible}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout='vertical'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={initialValues}
        onFieldsChange={onFormChange}
      >
        <Form.Item name="supported_languages" label={"Select languages for story"} initialValue={['en']}>
          <Checkbox.Group
            options={LIVE_SUPPORTED_LANGUAGES.map((lang) => ({ label: lang.alt_name, value: lang.value, disabled: lang.value === 'en' }))}
          />
        </Form.Item>
        <Container title='Header Text'>
          <Row>
            <Col>
              <Form.Item name='header_use_same' valuePropName="checked">
                <Checkbox >Use Hindi for all</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row justify='start'>
            {
              LIVE_SUPPORTED_LANGUAGES.filter((lang) => selectedLanguages.includes(lang.value)).map((lang) => {
                return (
                  <Col key={lang.value} span={8} style={{ paddingRight: "20px" }}>
                    <Form.Item
                      label={lang.alt_name}
                      name={['header_texts', lang.value]}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[
                        { required: true, message: 'Please input header text!' },
                      ]}
                    >
                      <TextArea placeholder='Enter header text' autoSize />
                    </Form.Item>
                  </Col>
                )
              })
            }
          </Row>
        </Container>

        <Container title='Description Text'>
          <Row>
            <Col>
              <Form.Item name='description_use_same' valuePropName="checked">
                <Checkbox >Use Hindi for all</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row justify='start'>
            {
              LIVE_SUPPORTED_LANGUAGES.filter((lang) => selectedLanguages.includes(lang.value)).map((lang) => {
                return (
                  <Col key={lang.value} span={8} style={{ paddingRight: "20px" }}>
                    <Form.Item
                      label={lang.alt_name}
                      name={['description_texts', lang.value]}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[
                        { required: true, message: 'Please input description text!' },
                      ]}
                    >
                      <TextArea rows={3} placeholder='Enter description text' autoSize />
                    </Form.Item>
                  </Col>
                )
              })
            }
          </Row>
        </Container>

        <Container title='Icon Text'>
          <Row>
            <Col>
              <Form.Item name='icon_use_same' valuePropName="checked">
                <Checkbox >Use Hindi for all</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row justify='start'>
            {LIVE_SUPPORTED_LANGUAGES.filter((lang) => selectedLanguages.includes(lang.value)).map((lang) => {
              return (
                <Col key={lang.value} span={8} style={{ paddingRight: "20px" }}>
                  <Form.Item
                    label={lang.alt_name}
                    name={['icon_texts', lang.value]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      { required: true, message: 'Please input icon text!' },
                    ]}
                  >
                    <TextArea placeholder='Enter icon text' autoSize />
                  </Form.Item>
                </Col>
              )
            })}
          </Row>
        </Container>

        <Container title='Share Text'>
          <Row>
            <Col>
              <Form.Item name='share_use_same' valuePropName="checked">
                <Checkbox >Use Hindi for all</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row justify='start'>
            {LIVE_SUPPORTED_LANGUAGES.filter((lang) => selectedLanguages.includes(lang.value)).map((lang) => {
              return (
                <Col key={lang.value} span={8} style={{ paddingRight: "20px" }}>
                  <Form.Item
                    label={lang.alt_name}
                    name={['share_text', lang.value]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      { required: true, message: 'Please input share text!' },
                    ]}
                  >
                    <TextArea placeholder='Enter share text' autoSize />
                  </Form.Item>
                </Col>
              )
            })}
          </Row>
        </Container>
        <Container title='CTA Text'>
          <Row>
            <Col span={4}>
              <Form.Item name='cta_use_same' valuePropName="checked">
                <Checkbox >Use Hindi for all</Checkbox>
              </Form.Item>
            </Col>

          </Row>
          <Row >
            <Col span={20}>
              <Form.Item
                label='CTA deeplink'
                name={['cta', 'deeplink']}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{
                  type: "url",
                  message: "This field must be a valid url."
                }]}
              >
                <TextArea placeholder='Enter cta deeplink' autoSize />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={20}>
              <Form.Item
                label='Is External Deeplink'
                name={['cta', 'is_external']}
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 24 }}
                initialValue={false}
              >
                <Checkbox />
              </Form.Item>
            </Col>
          </Row>
          <Row justify='start'>
            {
              LIVE_SUPPORTED_LANGUAGES.filter((lang) => selectedLanguages.includes(lang.value)).map((lang) => {
                return (
                  <Col key={lang.value} span={8} style={{ paddingRight: "20px" }}>
                    <Form.Item
                      label={lang.alt_name}
                      name={['cta', lang.value]}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[
                        {
                          validator(rule, value, callback) {
                            if (ctaDeeplink) {
                              if (!value) {
                                callback('Cta text is required when cta deeplink is present')
                              }
                            }
                            callback(undefined)
                          },
                        },
                      ]}
                    >
                      <TextArea rows={3} placeholder='Enter cta text' autoSize />
                    </Form.Item>
                  </Col>
                )
              })
            }
          </Row>
        </Container>
        <Form.Item
          label='Duration in Sec'
          name='duration_sec'
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ paddingTop: "30px" }}
          rules={[
            { required: true, message: 'Please input duration in second!' },
          ]}
        >
          <InputNumber placeholder='Enter duration in second' />
        </Form.Item>
        <Form.Item
          label='Order'
          name='order'
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: true, message: 'Please input order!' }]}
        >
          <InputNumber placeholder='Enter order' />
        </Form.Item>
        <Form.Item
          label='Select Icon Type'
          name='iconType'
          rules={[{ required: true, message: 'Please select icon type!' }]}
        >
          <Radio.Group>
            <Radio value={"IMAGE"}>Image</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.iconType !== currentValues.iconType
          }
        >
          {({ getFieldValue }) => (
            getFieldValue("iconType") === "IMAGE" && <Row>
              <Col span={12}>
                <Form.Item
                  label='icon Image'
                  name='icon'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  valuePropName='fileList'
                  rules={[
                    {
                      min: 1,
                      max: 1,
                      validator: async (rules: any, values: any) => {
                        if (values?.length < rules.min) {
                          return Promise.reject('Please upload image ')
                        }
                      },
                    },
                  ]}
                >
                  <S3Upload
                    preSignUrl={`${apiBaseUrl}/magneto/internal/v1/posts/attachment/upload?extension=`}
                    min={1}
                    max={1}
                    allowedFileExtension={[AllowedFormats.PNG, AllowedFormats.JPEG, AllowedFormats.JPG, AllowedFormats.WEBP]}
                    considerOnlyExtension={true}
                    headers={headers}
                    cdnUrl={url}
                    maxSizeInKiloBytes={300}
                  />
                </Form.Item>
              </Col>

            </Row>
          )}
        </Form.Item>

        <Form.Item
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          name='startAndEndDay'
          required={true}
          label='Start and End Date'
          rules={[
            {
              validator: startAndEndDateValidator,
            },
          ]}
        >
          <RangePicker
            ranges={{
              Today: [moment().startOf('day'), moment().endOf('day')],
            }}
            showTime
            format={'YYYY-MM-DD HH:mm:ss'}
          />
        </Form.Item>
        <Button type='primary' style={{ padding: '0px 100px' }} onClick={applyAttechmentToAllTheLanguages} size='small' disabled={!isAttechment}>Click to Copy attechment to all the languages </Button>
        <Row justify='start' align='top' style={{ paddingBottom: '60px' }}>
          {
            LIVE_SUPPORTED_LANGUAGES.filter((lang) => selectedLanguages.includes(lang.value)).map((lang) => {
              return (<Col key={lang.value} span={4}>
                <Container title={lang.alt_name}>
                  <Row align='top'>
                    <Form.Item
                      label=' Attachment Type'
                      name={["attachment", lang.value, "type"]}
                      rules={[{ required: true, message: 'Please select Attachment type!' }]}
                    >
                      <Radio.Group>
                        <Space direction="vertical">
                          <Radio value={"VIDEO"}>Video</Radio>
                          <Radio value={"IMAGE"}>Image</Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues?.attachment?.[lang.value]?.type !== currentValues?.attachment?.[lang.value]?.type
                      }
                    >
                      {({ getFieldValue }) => (
                        getFieldValue(["attachment", lang.value, "type"]) === "VIDEO" && <Row>
                          <Col span={24}>
                            <Form.Item
                              name={['attachment', lang.value, 'attachment']}
                              labelCol={{ span: 24 }}
                              wrapperCol={{ span: 24 }}
                              valuePropName='fileList'
                              rules={[
                                {
                                  min: 0,
                                  max: 1,
                                  validator: async (rules: any, values: any) => {
                                    if (values?.length < rules.min) {
                                      return Promise.reject('Please upload MP4 media url')
                                    }
                                  },
                                },
                              ]}
                            >

                              <S3Upload
                                preSignUrl={`${apiBaseUrl}/magneto/internal/v1/posts/attachment/upload?extension=`}
                                min={1}
                                max={1}
                                allowedFileExtension={[
                                  AllowedFormats.MP4,

                                ]}
                                cdnUrl={url}
                                considerOnlyExtension={true}
                                headers={headers}
                                maxSizeInKiloBytes={26500}
                              />
                            </Form.Item>
                          </Col>

                        </Row>

                      )}
                    </Form.Item>

                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues?.attachment?.[lang.value]?.type !== currentValues?.attachment?.[lang.value]?.type
                      }
                    >
                      {({ getFieldValue }) => (
                        getFieldValue(['attachment', lang.value, "type"]) === "IMAGE" && <Row>
                          <Col span={24}>
                            <Form.Item
                              name={['attachment', lang.value, 'attachment']}
                              labelCol={{ span: 24 }}
                              wrapperCol={{ span: 24 }}
                              valuePropName='fileList'
                              rules={[
                                {
                                  min: 1,
                                  max: 1,
                                  validator: async (rules: any, values: any) => {
                                    if (values?.length < rules.min) {
                                      return Promise.reject('Please upload image ')
                                    }
                                  },
                                },
                              ]}
                            >

                              <S3Upload
                                preSignUrl={`${apiBaseUrl}/magneto/internal/v1/posts/attachment/upload?extension=`}
                                min={1}
                                max={1}
                                allowedFileExtension={[AllowedFormats.PNG, AllowedFormats.JPEG, AllowedFormats.JPG, AllowedFormats.GIF, AllowedFormats.WEBP]}
                                considerOnlyExtension={true}
                                headers={headers}
                                cdnUrl={url}
                                maxSizeInKiloBytes={300}
                              />
                            </Form.Item>
                          </Col>

                        </Row>

                      )}
                    </Form.Item>
                  </Row>
                </Container>
              </Col>)
            })}
        </Row>
        <Row justify='start'>
          <Form.Item name={"service_type"} label={"Service Type"} style={{ width: '20%' }}>
            <Select allowClear placeholder="Select a service type" disabled={(isEditJourney && (initialValues?.service_type !== null))}>
              <Select.Option value={'puja'}>Puja</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item shouldUpdate style={{ width: '60%', padding: "0 10px" }}>
            {({ getFieldValue }) =>
            (
              <Form.Item
                label="Thumbnail Path"
                name={'thumbnail_path'}
                style={{ width: '20%', padding: "0 10px" }}
                rules={[
                  {
                    required: !!getFieldValue('service_type'),
                    message: 'Thumbnail is required',
                    min: 0,
                    max: 1,
                    validator: async (rules: any, values: any) => {
                      if (getFieldValue('service_type') && (!values || values.length === 0)) {
                        return Promise.reject('Please upload Media')
                      }
                      if (values?.length > rules.max) {
                        return Promise.reject('Maximum 1 file allowed')
                      }
                    },
                  },
                ]}
                valuePropName='fileList'
              >
                <S3Upload
                  preSignUrl={`${YODA_PRESIGN_URL}`}
                  min={0}
                  max={1}
                  allowedFileExtension={[
                    AllowedFormats.PNG,
                    AllowedFormats.JPEG,
                    AllowedFormats.JPG,
                    AllowedFormats.WEBP,

                  ]}
                  cdnUrl={YODA_CDN_URL}
                // considerOnlyExtension={true}
                // headers={headers}
                // maxSizeInKiloBytes={26500}
                />
              </Form.Item>
            )
            }
          </Form.Item>
        </Row>

        <Form.Item shouldUpdate>
          {({ getFieldValue }) =>
            getFieldValue("service_type") === "puja" ? (
              <Container title='Specificity Texts'>
                <Row>
                  <Col>
                    <Form.Item name='specificity_use_same' valuePropName="checked">
                      <Checkbox>Use Hindi for all</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify='start'>
                  {LIVE_SUPPORTED_LANGUAGES.filter((lang) => selectedLanguages.includes(lang.value)).map((lang) => (
                    <Col key={lang.value} span={8} style={{ paddingRight: "20px" }}>
                      <Form.Item
                        label={lang.alt_name}
                        name={["service_data", "specificity_texts", lang.value]}
                        // labelCol={{ span: 24 }}
                        // wrapperCol={{ span: 24 }}
                        rules={[{ required: true, message: 'Please input specificity text!' }]}
                      >
                        <TextArea rows={3} placeholder='Enter specificity text' autoSize />
                      </Form.Item>
                    </Col>
                  ))}
                </Row>
              </Container>
            ) : null
          }
        </Form.Item>

        <Row>
          <Form.Item>
            {isEditJourney ? (
              <>
                <Button type='primary' onClick={formSubmithandler} disabled={form?.getFieldValue("startAndEndDay")?.[1]?.isBefore()}>
                  Update Story
                </Button>
                <Popconfirm
                  placement="top"
                  title={'Are you sure you want to delete this story?'}
                  onConfirm={() => deleteStory(initialValues.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button style={{ marginLeft: '10px' }} type='default' >Delete</Button>
                </Popconfirm>
              </>
            ) : (
              <Button type='primary' onClick={formSubmithandler}>
                Create Story
              </Button>
            )}
          </Form.Item>
        </Row>
      </Form>
    </Drawer >
  )
}
export default StoryCreation
