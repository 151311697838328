import moment from 'moment'

const getPath = (input: string) => {
  try {
    return new URL(input).pathname
  } catch {
    return input
  }
}

export const formatStoryCreation = (formData: any): any => {
  delete formData?.header_use_same
  delete formData?.description_use_same
  delete formData?.icon_use_same
  delete formData?.share_use_same
  delete formData?.cta_use_same
  delete formData?.specificity_use_same

  const formatedObject: any = {
    ...formData,
    start_time: formData?.startAndEndDay?.[0]?.unix(),
    end_time: formData?.startAndEndDay?.[1]?.unix(),
    thumbnail_path: getPath(formData?.thumbnail_path?.[0]),

    icon: {
      type: formData?.iconType,
      path: formData?.icon?.[0]?.split('/').pop(),
    },
    // service_data: { specificity_texts: formData?.specificity_texts },
  }
  if (formatedObject?.cta?.deeplink) {
    formatedObject['cta']['type'] = 'BUTTON'
  } else {
    formatedObject['cta'] = null
  }

  if (formatedObject?.cta) {
    formatedObject['cta']['is_external'] = !!formData?.cta?.is_external
  }

  if (formatedObject['attachment']['hi']) {
    formatedObject['attachment']['hi']['path'] = formatedObject['attachment'][
      'hi'
    ]['attachment']?.[0]
      ?.split('/')
      .pop()
    delete formatedObject['attachment']['hi']['attachment']
  }
  if (formatedObject['attachment']['gu']) {
    formatedObject['attachment']['gu']['path'] = formatedObject['attachment'][
      'gu'
    ]['attachment']?.[0]
      ?.split('/')
      .pop()
    delete formatedObject['attachment']['gu']['attachment']
  }
  if (formatedObject['attachment']['mr']) {
    formatedObject['attachment']['mr']['path'] = formatedObject['attachment'][
      'mr'
    ]['attachment']?.[0]
      ?.split('/')
      .pop()
    delete formatedObject['attachment']['mr']['attachment']
  }
  if (formatedObject['attachment']['hi-hr']) {
    formatedObject['attachment']['hi-hr']['path'] = formatedObject[
      'attachment'
    ]['hi-hr']['attachment']?.[0]
      ?.split('/')
      .pop()
    delete formatedObject['attachment']['hi-hr']['attachment']
  }
  if (formatedObject['attachment']['hi-bj']) {
    formatedObject['attachment']['hi-bj']['path'] = formatedObject[
      'attachment'
    ]['hi-bj']['attachment']?.[0]
      ?.split('/')
      .pop()
    delete formatedObject['attachment']['hi-bj']['attachment']
  }
  if (formatedObject['attachment']['hi-rj']) {
    formatedObject['attachment']['hi-rj']['path'] = formatedObject[
      'attachment'
    ]['hi-rj']['attachment']?.[0]
      ?.split('/')
      .pop()
    delete formatedObject['attachment']['hi-rj']['attachment']
  }
  if (formatedObject['attachment']['en']) {
    formatedObject['attachment']['en']['path'] = formatedObject['attachment'][
      'en'
    ]['attachment']?.[0]
      ?.split('/')
      .pop()
    delete formatedObject['attachment']['en']['attachment']
  }

  // delete formatedObject?.specificity_texts
  delete formatedObject?.attachmentType
  delete formatedObject?.iconType
  delete formatedObject?.startAndEndDay
  return formatedObject
}
export const parseStoryCreation = (formData: any): any => {
  const formatedObject: any = {
    ...formData,
    startAndEndDay: [
      moment.unix(formData?.start_time),
      moment.unix(formData?.end_time),
    ],
    iconType: formData?.icon?.type,
    icon: [formData?.icon?.path],
    thumbnail_path: [formData?.thumbnail_path],
  }
  if (formatedObject?.attachment?.hi?.path) {
    formatedObject['attachment']['hi']['attachment'] = [
      formatedObject['attachment']['hi']['path'],
    ]
  }
  if (formatedObject?.attachment?.gu?.path) {
    formatedObject['attachment']['gu']['attachment'] = [
      formatedObject['attachment']['gu']['path'],
    ]
  }
  if (formatedObject?.attachment?.mr?.path) {
    formatedObject['attachment']['mr']['attachment'] = [
      formatedObject['attachment']['mr']['path'],
    ]
  }
  if (formatedObject?.attachment?.['hi-hr']?.path) {
    formatedObject['attachment']['hi-hr']['attachment'] = [
      formatedObject['attachment']['hi-hr']['path'],
    ]
  }
  if (formatedObject?.['attachment']?.['hi-bj']?.['path']) {
    formatedObject['attachment']['hi-bj']['attachment'] = [
      formatedObject['attachment']['hi-bj']['path'],
    ]
  }
  if (formatedObject?.['attachment']?.['hi-rj']?.['path']) {
    formatedObject['attachment']['hi-rj']['attachment'] = [
      formatedObject['attachment']['hi-rj']['path'],
    ]
  }
  if (formatedObject?.['attachment']?.['en']?.['path']) {
    formatedObject['attachment']['en']['attachment'] = [
      formatedObject['attachment']['en']['path'],
    ]
  }

  return formatedObject
}
