import { Form, Input, Row } from "antd"
import React from "react"
import Banners from "./Banners"

interface Props {
    cdnUrl: string
    preSignUrl: string
    type: string
}

const InfoMediaWeb: React.FC<Props> = ({ cdnUrl, preSignUrl, type }) => {
    return (
        <>
            <Form.Item
                label={`Title`}
                name={['data', 'info_media_web', 'title']}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ flexBasis: '40%' }}
                rules={[
                    {
                        required: false,
                        message: 'Enter Title',
                    },
                ]}
            >
                <Input placeholder='Enter Title' />
            </Form.Item>

            <Form.Item
                label={`Sub Title`}
                name={['data', 'info_media_web', 'sub_title']}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ flexBasis: '45%' }}
                rules={[
                    {
                        required: false,
                        message: 'Enter subtitle',
                    },
                ]}
            >
                <Input placeholder='Please type Sub Title' />
            </Form.Item>
            <Form.List name={['data', 'info_media_web', 'media_list']}>
                {(fields, { add, remove, move }) => (
                    <>
                        <Row justify="space-between" style={{ width: '100%' }}>
                            <Banners
                                onSortEnd={({ oldIndex, newIndex }) => {
                                    console.log('oldIndex', oldIndex, 'newIndex', newIndex)
                                    move(oldIndex, newIndex)
                                }}
                                distance={1}
                                helperClass='sortableHelper'
                                fields={fields}
                                add={add}
                                remove={remove}
                                cdnUrl={cdnUrl}
                                preSignUrl={preSignUrl}
                                datatype='Info media web'
                            />
                        </Row>
                    </>
                )}
            </Form.List>
        </>
    )
}

export default InfoMediaWeb